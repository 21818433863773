<template>
    <div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label for="title">section title</label>
                <text-input
                  id="title"
                  v-model="section.title"
                  autocomplete="off"
                />
                <span v-if="hasError('section.title')" class="form-error">{{ showError('section.title') }}</span>

                <label for="subTitle" class="mt-8"> section subtitle</label>
                <text-input
                  id="subTitle"
                  v-model="section.subTitle"
                  autocomplete="off"
                />
                <span v-if="hasError('section.subTitle')" class="form-error">{{ showError('section.subTitle') }}</span>
            </div>
            <div class="form-col">
                <label for="buttonText">button text</label>
                <text-input
                  id="buttonText"
                  v-model="section.buttonText"
                  autocomplete="off"
                />
                <label class="mt-8">button link</label>
                <url-input v-model="section.buttonLink" :website-id="websiteId"></url-input>
            </div>
        </div>
        <div class="p-8" style="background: #f9f9f9">
            <div class="form-row form-row_grid">
                <div class="form-col">
                    <label class="mb-1">image one</label>
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                        :libraryFilters="['marketing', 'cms']"
                        :max-nb-of-files="1"
                        :max-upload-size="20"
                        :cropper-options="{aspectRatio: 9 / 16, cropBoxResizable: true, zoomOnWheel: false}"
                        :noCropper="true"
                        @uploaded-to-s3="createWebsiteImage"
                        v-model="section.imageOne"
                    ></file-manager>
                    <span v-if="hasError('section.imageOne')" class="form-error">{{ showError('section.imageOne') }}</span>
                </div>
                <div class="form-col">
                    <label for="imageOneTitle">image one title</label>
                    <text-input
                      id="imageOneTitle"
                      v-model="section.imageOneTitle"
                      autocomplete="off"
                    />
                    <span v-if="hasError('section.imageOneTitle')" class="form-error">{{ showError('section.imageOneTitle') }}</span>

                    <label for="imageOneCaption" class="mt-8">image one caption</label>
                    <text-input
                      id="imageOneCaption"
                      v-model="section.imageOneCaption"
                      autocomplete="off"
                    />
                </div>
            </div>
            <div class="divider border border-top border-gray-200 mb-10 mt-10"></div>
            <div class="form-row form-row_grid">
                <div class="form-col">
                    <label class="mb-1">image two</label>
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                        :libraryFilters="['marketing', 'cms']"
                        :max-nb-of-files="1"
                        :max-upload-size="20"
                        :cropper-options="{aspectRatio: 9 / 16, cropBoxResizable: true, zoomOnWheel: false}"
                        :noCropper="true"
                        @uploaded-to-s3="createWebsiteImage"
                        v-model="section.imageTwo"
                    ></file-manager>
                    <span v-if="hasError('section.imageTwo')" class="form-error">{{ showError('section.imageTwo') }}</span>
                </div>
                <div class="form-col">
                    <label for="imageTwoTitle">image two title</label>
                    <text-input
                      id="imageTwoTitle"
                      v-model="section.imageTwoTitle"
                      autocomplete="off"
                    />

                    <span v-if="hasError('section.imageTwoTitle')" class="form-error">{{ showError('section.imageTwoTitle') }}</span>

                    <label for="imageTwoCaption" class="mt-8">image two caption</label>
                    <text-input
                      id="imageTwoCaption"
                      v-model="section.imageTwoCaption"
                      autocomplete="off"
                    />
                </div>
            </div>
            <div class="divider border border-top border-gray-200 mb-10 mt-10"></div>
            <div class="form-row form-row_grid">
                <div class="form-col">
                    <label class="mb-1">image three</label>
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                        :libraryFilters="['marketing', 'cms']"
                        :max-nb-of-files="1"
                        :max-upload-size="20"
                        :cropper-options="{aspectRatio: 9 / 16, cropBoxResizable: true, zoomOnWheel: false}"
                        :noCropper="true"
                        @uploaded-to-s3="createWebsiteImage"
                        v-model="section.imageThree"
                    ></file-manager>
                    <span v-if="hasError('section.imageThree')" class="form-error">{{ showError('section.imageThree') }}</span>
                </div>
                <div class="form-col">
                    <label for="imageThreeTitle">image three title</label>
                    <text-input
                      id="imageThreeTitle"
                      v-model="section.imageThreeTitle"
                      autocomplete="off"
                    />
                    <span v-if="hasError('section.imageThreeTitle')" class="form-error">{{ showError('section.imageThreeTitle') }}</span>

                    <label for="imageThreeCaption" class="mt-8">image three caption</label>
                    <text-input
                      id="imageThreeCaption"
                      v-model="section.imageThreeCaption"
                      autocomplete="off"
                    />
                </div>
            </div>
            <div class="divider border border-top border-gray-200 mb-10 mt-10"></div>
            <div class="form-row form-row_grid">
                <div class="form-col">
                    <label class="mb-1">image four</label>
                    <file-manager
                        :main-directory="'cms'"
                        :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                        :libraryFilters="['marketing', 'cms']"
                        :max-nb-of-files="1"
                        :max-upload-size="20"
                        :cropper-options="{aspectRatio: 9 / 16, cropBoxResizable: true, zoomOnWheel: false}"
                        :noCropper="true"
                        @uploaded-to-s3="createWebsiteImage"
                        v-model="section.imageFour"
                    ></file-manager>
                    <span v-if="hasError('section.imageFour')" class="form-error">{{ showError('section.imageFour') }}</span>
                </div>
                <div class="form-col">
                    <label for="imageFourTitle">image four title</label>
                    <text-input
                      id="imageFourTitle"
                      v-model="section.imageFourTitle"
                      autocomplete="off"
                    />
                    <span v-if="hasError('section.imageFourTitle')" class="form-error">{{ showError('section.imageFourTitle') }}</span>

                    <label for="imageFourCaption" class="mt-8">image four caption</label>
                    <text-input
                      id="imageFourCaption"
                      v-model="section.imageFourCaption"
                      autocomplete="off"
                    />
                </div>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";
import UrlInput from "@/components/cms/UrlInput";
import { toolbarConfig } from "@/utils/RichTextEditor";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import FileManager from "@/components/ui/filemanager/FileManager";
import TextInput from "@/components/ui/TextInput";

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter, UrlInput, FileManager, TextInput },
    emits: ['save-page-widget'],
    data: () => {
        return {
            section: {
                title: null,
                subTitle: null,
                buttonText: null,
                buttonLink: null,
                imageOne: [],
                imageOneCaption: null,
                imageOneTitle: null,
                imageTwo: [],
                imageTwoCaption: null,
                imageTwoTitle: null,
                imageThree: [],
                imageThreeCaption: null,
                imageThreeTitle: null,
                imageFour: [],
                imageFourCaption: null,
                imageFourTitle: null,
            },
            customToolbar: toolbarConfig,
            websiteId: null,
            isProcessing: false,
        }
    },
    computed: {
        ...mapGetters({
            getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
            getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
            getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
        })
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.isProcessing = true;
                this.section.imageOne = (this.section.imageOne.length) ? this.getThumbnailUrlByFileId(this.section.imageOne[0]) : '';
                this.section.imageTwo = (this.section.imageTwo.length) ? this.getThumbnailUrlByFileId(this.section.imageTwo[0]) : '';
                this.section.imageThree = (this.section.imageThree.length) ? this.getThumbnailUrlByFileId(this.section.imageThree[0]) : '';
                this.section.imageFour = (this.section.imageFour.length) ? this.getThumbnailUrlByFileId(this.section.imageFour[0]) : '';
                this.$emit('save-page-widget', Object.assign({}, this.section));
            }
        },
    },
    validations: {
        'section.title' : 'required',
        'section.subTitle' : 'required',
        'section.imageOne': 'required',
        'section.imageTwo': 'required',
        'section.imageThree': 'required',
        'section.imageFour': 'required',
        'section.imageOneTitle': 'required',
        'section.imageTwoTitle': 'required',
        'section.imageThreeTitle': 'required',
        'section.imageFourTitle': 'required',

    },
    created() {
        this.initValidator();
        this.websiteId = this.$route.params.websiteId;

        if (this.values.length) {
            Object.assign(this.section, {
                title: this.getValue('title'),
                subTitle: this.getValue('subTitle'),
                buttonText: this.getValue('buttonText'),
                buttonLink: this.getValue('buttonLink'),

                imageOne: (this.getValue('imageOne')) ? [ this.getFileIdByThumbnailUrl(this.getValue('imageOne')) ] : [],
                imageOneCaption: this.getValue('imageOneCaption'),
                imageOneTitle: this.getValue('imageOneTitle'),

                imageTwo: (this.getValue('imageTwo')) ? [ this.getFileIdByThumbnailUrl(this.getValue('imageTwo')) ] : [],
                imageTwoCaption: this.getValue('imageTwoCaption'),
                imageTwoTitle: this.getValue('imageTwoTitle'),

                imageThree: (this.getValue('imageThree')) ? [ this.getFileIdByThumbnailUrl(this.getValue('imageThree')) ] : [],
                imageThreeCaption: this.getValue('imageThreeCaption'),
                imageThreeTitle: this.getValue('imageThreeTitle'),

                imageFour: (this.getValue('imageFour')) ? [ this.getFileIdByThumbnailUrl(this.getValue('imageFour')) ] : [],
                imageFourCaption: this.getValue('imageFourCaption'),
                imageFourTitle: this.getValue('imageFourTitle'),
            });
        }
    }
}
</script>
